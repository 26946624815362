<!-- header start -->
<div class="header-section d-block w-100">
    <div class="header-box">
        <div class="container text-white">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-xl-7 col-md-6 col-sm-12">
                            <div class="header-left">
                                <div class="header-title-1">API Maker</div>
                                <!-- <div class="header-title-2">The complete</div> -->
                                <div class="typed-header">
                                    <div class="header-title-3 text">
                                        <!-- data-words="The complete backend solution,Generates 15/34 APIs for each table" -->
                                        <span class="typer" id="mainHeaderTyping"
                                              data-words="The complete backend solution,Makes you enjoy development,Generates 15-34 APIs for each table,Saves 4x time & money in development,Deploys your code in ~15 seconds"
                                              data-delay="20"
                                              data-deleteDelay="3500">
                                        </span>
                                        <span class="cursor" data-owner="mainHeaderTyping"></span>
                                        <!--<span style="font-size:1em; vertical-align:middle;" class="cursor"
                                              data-cursorDisplay="|" data-owner="mainHeaderTyping"></span>-->
                                    </div>
                                </div>
                                <div class="sub-header-title">for individual freelancers to giant enterprises</div>
                                <div class="w-100 header-btn d-none d-sm-none d-xl-block d-lg-block d-md-block ">
                                    <a href="https://docs.apimaker.dev/" target="_blank" class="common-btn">Getting
                                        Started</a>
                                </div>
                            </div>

                        </div>
                        <div class="col-xl-5 col-md-6 col-sm-12">
                            <div class="header-right">
                                <div class="header-rt-box">
                                    <img src="../../assets/images/header-rt-pc.png" alt="API Maker" title="API Maker">
                                    <div class="pc-screen-box">
                                        <img src="../../assets/images/screen-01.png" alt="API Maker Dashboard" title="API Maker Dashboard">
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 text-center header-btn d-block d-sm-block d-xl-none d-lg-none d-md-none">
                                <a href="https://docs.apimaker.dev/" target="_blank" class="common-btn">Getting
                                    Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<!-- Trusted  start -->
<div class="trusted-section pt-5 pb-5">
    <h2 class="px-3">Trusted By over 10+ IT Companies using <span class="light-text">API Maker</span></h2>
    <div id="owl-carousel2" class="owl-carousel  owl-theme trusted-list-logos">
        <div class="slider-logo-box"><img src="../../assets/images/slider-logo-1.png" alt="Sava Info Systems"
                title="Sava Info Systems"></div>
        <div class="slider-logo-box"><img src="../../assets/images/slider-logo-12.png" alt="3 Way Technologies"
                title="3 Way Technologies"></div>
        <div class="slider-logo-box"><img src="../../assets/images/slider-logo-2.png" alt="D South" title="D south"></div>
        <div class="slider-logo-box other-logo-box"><img src="../../assets/images/slider-logo-3.png" alt="Borough Taxi"
                title="Borough Taxi"></div>
        <div class="slider-logo-box"><img src="../../assets/images/slider-logo-4.png" alt="Apollo" title="Apollo"></div>
        <div class="slider-logo-box"><img src="../../assets/images/slider-logo-5.png" alt="WCST" title="WCST"></div>
        <div class="slider-logo-box"><img src="../../assets/images/slider-logo-6.png" alt="Toupto" title="Toupto"></div>
        <div class="slider-logo-box"><img src="../../assets/images/slider-logo-7.png" alt="Folium" title="Folium"></div>
        <div class="slider-logo-box"><img src="../../assets/images/slider-logo-8.png" alt="App Tale" title="App Tale"></div>
        <div class="slider-logo-box"><img src="../../assets/images/slider-logo-9.png" alt="Desire Info Web" title="Desire Info web">
        </div>
        <div class="slider-logo-box"><img src="../../assets/images/slider-logo-10.png" alt="Mart2Global" title="Mart2Global">
        </div>
        <div class="slider-logo-box"><img src="../../assets/images/slider-logo-11.png" alt="R & D" title="R & D"></div>

    </div>
</div>
<!-- Trusted  End -->
<!-- Features  start -->
<div class="features-section pt-5 pb-4">
    <h2 class="px-3">We Provide Many <span class="light-text">Features</span> You Can Use</h2>
    <div class="w-100 mt-4 mt-lg-4 mt-xl-5 mt-md-4 mt-sm-4">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <!-- left start-->
                        <div class="col-xl-7 col-md-6 col-sm-12">
                            <div class="screen-box w-100 d-block">
                                <img src="../../assets/images/features-screen-1.png" alt="API Maker Features" title="API Maker Features">
                            </div>
                        </div>
                        <!-- left End-->
                        <!-- right start-->
                        <div class="col-xl-5 col-md-6 col-sm-12">
                            <div class="features-list w-100 d-block">
                                <div class="common-listing mt-4 mt-lg-4 mt-xl-4 mt-md-4 mt-sm-4">
                                    <ul>
                                        <li>Auto Generated APIs For 8 DB Types</li>
                                        <li>Custom APIs</li>
                                        <li>Schedulers</li>
                                        <li>WebSocket Notifications</li>
                                        <li>Git Integration</li>
                                        <li>Automatic Caching</li>
                                        <li>Much more...</li>
                                    </ul>
                                </div>
                                <div class="w-100 mt-4 mt-lg-4 mt-xl-5 mt-md-4 mt-sm-4">
                                    <button class="common-btn" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/api_maker?hide_gdpr_banner=1&amp;background_color=1a1a1a&amp;text_color=e4e4e4&amp;primary_color=df320c'});return false;">
                                        <span>Live Demo</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!--right End-->
                    </div>
                </div>
            </div>
            <!-- freeware Chips box start -->
            <div class="w-100 mt-4 mt-lg-4 mt-xl-5 mt-md-4 mt-sm-4">
                <h3 class="mb-1"><span class="rocket-icon"><img src="../../assets/images/logo-icon.png" alt="API Maker® - New era of backend begins"
                            title="API Maker® - New era of backend begins"></span>API Maker is
                    Freeware</h3>
                <div class="w-100  d-flex flex-wrap align-items-center">
                    <div class="freeware-chips-box mt-2 mb-2 me-3 d-flex align-items-center"><span [innerHTML]="htmlToAdd" id="npm_text"></span>
                    </div>
                    <div class="freeware-chips-box mt-2 mb-2 me-3 d-flex align-items-center">
                        <button class="button-chips"(click)="copyContent()" title="Copy to clipboard">
                            <span class="freeware-icon"><i class="fa-solid fa-copy"></i></span>Copy</button>
                    </div>
                    <div class="mt-2 mb-2 me-3 d-flex align-items-center"><a class="freeware-chips-box"
                            href="https://www.npmjs.com/package/@sava-info-systems/api-maker" target="_blank"><span
                                class="freeware-icon"><i class="fa-brands fa-npm"></i></span>NPM</a></div>
                    <div class="mt-2 mb-2 me-3 d-flex align-items-center"><a class="freeware-chips-box"
                            href="https://hub.docker.com/r/savainfosystems/api_maker_be_fe" target="_blank"><span
                                class="freeware-icon"><i class="fa-brands fa-docker"></i></span>DockerHub</a></div>
                    <div class="mt-2 mb-2 me-3 d-flex align-items-center"><a class="freeware-chips-box"
                            href="https://github.com/APIMaker-dev/api_maker_docker_image" target="_blank"><span
                                class="freeware-icon"><i class="fa-solid fa-laptop-code"></i></span>Local
                            Install</a>
                    </div>
                </div>
                <div class="w-100 d-block freeware-short-info">Run above command in your blank VPS. Minimum 1GB RAM
                    | 1 CPU | 20GB Storage | Ubuntu 22.04 LTS</div>
            </div>
            <!-- freeware Chips box  end -->
            <!-- freeware info start -->
            <div class="freeware-info mt-5">API Maker is 100% freeware backend framework like ASP.Net | Spring Boot
                | Laravel | Ruby On Rails | NestJS |Django but API Maker is on steroids</div>
            <!-- freeware info End -->
        </div>
    </div>
</div>
<!-- Features  End -->
<!-- video  start -->
<div class="video-section pt-4 pb-4">
    <h2 class="px-3">API Maker <span class="light-text">Videos</span></h2>
    <div class="w-100 mt-4 mt-lg-4 mt-xl-5 mt-md-4 mt-sm-4">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <!-- video left start -->
                        <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6">
                            <div class="d-block video-box mb-3 mb-lg-3 mb-xl-3 mb-md-3 mb-sm-3">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <video controls="" poster="../../assets/images/walkthrough.png">
                                        <source
                                            src="https://images.apimaker.dev/videos/twitter/0_Summary_Video_no_cloud.mp4"
                                            type="video/mp4">
                                    </video>
                                </div>
                            </div>
                        </div>
                        <!-- video left End -->
                        <!-- video right end -->
                        <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6">
                            <div class="d-block video-box mb-3 mb-lg-3 mb-xl-3 mb-md-3 mb-sm-3">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <video controls="" poster="../../assets/images/install_in_vps.png">
                                        <source
                                            src="https://images.apimaker.dev/videos/twitter/Install_API_Maker_in_VPS_Mute_For_Website.mp4"
                                            type="video/mp4">
                                    </video>
                                </div>
                            </div>
                        </div>
                        <!-- video right end -->
                    </div>
                </div>
                <div class="col-md-12 text-center mt-4 mt-lg-4 mt-xl-4 mt-md-4 mt-sm-4">
                    <a routerLink="/video" (click)="scrollToTop()" class="common-btn">Show More</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- video  End -->
<!-- Backend API Generation & Development Solution start -->
<div class="solution-section pt-4 pb-4">
    <div class="container">
        <h2 class="px-3"><span class="light-text d-block">World's No.1</span> Backend API Generation & Development Solution </h2>
        <div class="w-100 mt-4 mt-lg-4 mt-xl-5 mt-md-4 mt-sm-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <!-- box 1 start -->
                        <div class="col-12 col-lg-4 col-xl-4 col-md-6 col-sm-6 benefits-list mb-4">
                            <div class="benefits-box d-flex align-items-center justify-content-center">
                                <div class="benefits-cnt w-100">
                                    <div class="benefits-icon-box mx-auto">
                                        <div class="benefits-icon d-flex align-items-center justify-content-center">
                                            <img src="../../assets/images/benefits-1.png" alt="Low Development Cost"
                                                title="Low Development Cost">
                                        </div>
                                    </div>
                                    <div class="benefits-name text-center px-2">Low Development Cost</div>
                                </div>
                            </div>
                        </div>
                        <!-- box 1 end -->
                        <!-- box 2 start -->
                        <div class="col-12 col-lg-4 col-xl-4 col-md-6 col-sm-6 benefits-list mb-4">
                            <div class="benefits-box d-flex align-items-center justify-content-center">
                                <div class="benefits-cnt w-100">
                                    <div class="benefits-icon-box mx-auto">
                                        <div class="benefits-icon d-flex align-items-center justify-content-center">
                                            <img src="../../assets/images/benefits-2.png" alt="Fast & Robust Project Development"
                                                title="Fast & Robust Project Development">
                                        </div>
                                    </div>
                                    <div class="benefits-name text-center px-2">Fast & Robust Project Development
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- box 2 end -->
                        <!-- box 3 start -->
                        <div class="col-12 col-lg-4 col-xl-4 col-md-6 col-sm-6 benefits-list mb-4">
                            <div class="benefits-box d-flex align-items-center justify-content-center">
                                <div class="benefits-cnt w-100">
                                    <div class="benefits-icon-box mx-auto">
                                        <div class="benefits-icon d-flex align-items-center justify-content-center">
                                            <img src="../../assets/images/benefits-3.png" alt="Low costing of servers"
                                                title="Low costing of servers">
                                        </div>
                                    </div>
                                    <div class="benefits-name text-center px-2">Low costing of servers</div>
                                </div>
                            </div>
                        </div>
                        <!-- box 3 end -->
                        <!-- box 4 start -->
                        <div class="col-12 col-lg-4 col-xl-4 col-md-6 col-sm-6 benefits-list mb-4">
                            <div class="benefits-box d-flex align-items-center justify-content-center">
                                <div class="benefits-cnt w-100">
                                    <div class="benefits-icon-box mx-auto">
                                        <div class="benefits-icon d-flex align-items-center justify-content-center">
                                            <img src="../../assets/images/benefits-4.png" alt="Low Development Cost"
                                                title="Low Development Cost">
                                        </div>
                                    </div>
                                    <div class="benefits-name text-center px-2">Low Development Cost</div>
                                </div>
                            </div>
                        </div>
                        <!-- box 4 end -->
                        <!-- box 5 start -->
                        <div class="col-12 col-lg-4 col-xl-4 col-md-6 col-sm-6 benefits-list mb-4">
                            <div class="benefits-box d-flex align-items-center justify-content-center">
                                <div class="benefits-cnt w-100">
                                    <div class="benefits-icon-box mx-auto">
                                        <div class="benefits-icon d-flex align-items-center justify-content-center">
                                            <img src="../../assets/images/benefits-5.png" alt="No More Code Deployments"
                                                title="No More Code Deployments">
                                        </div>
                                    </div>
                                    <div class="benefits-name text-center px-2">No More Code Deployments</div>
                                </div>
                            </div>
                        </div>
                        <!-- box 5 end -->
                        <!-- box 6 start -->
                        <div class="col-12 col-lg-4 col-xl-4 col-md-6 col-sm-6 benefits-list mb-4">
                            <div class="benefits-box d-flex align-items-center justify-content-center">
                                <div class="benefits-cnt w-100">
                                    <div class="benefits-icon-box mx-auto">
                                        <div class="benefits-icon d-flex align-items-center justify-content-center">
                                            <img src="../../assets/images/benefits-6.png" alt="Query Data Across 8 DB Types"
                                                title="Query Data Across 8 DB Types">
                                        </div>
                                    </div>
                                    <div class="benefits-name text-center px-2">Query Data Across 8 DB Types</div>
                                </div>
                            </div>
                        </div>
                        <!-- box 6 end -->
                        <!-- box 7 start -->
                        <div class="col-12 col-lg-4 col-xl-4 col-md-6 col-sm-6 benefits-list mb-4">
                            <div class="benefits-box d-flex align-items-center justify-content-center">
                                <div class="benefits-cnt w-100">
                                    <div class="benefits-icon-box mx-auto">
                                        <div class="benefits-icon d-flex align-items-center justify-content-center">
                                            <img src="../../assets/images/benefits-7.png" alt="Multilayer Security"
                                                title="Multilayer Security">
                                        </div>
                                    </div>
                                    <div class="benefits-name text-center px-2">Multilayer Security</div>
                                </div>
                            </div>
                        </div>
                        <!-- box 7 end -->
                        <!-- box 8 start -->
                        <div class="col-12 col-lg-4 col-xl-4 col-md-6 col-sm-6 benefits-list mb-4">
                            <div class="benefits-box d-flex align-items-center justify-content-center">
                                <div class="benefits-cnt w-100">
                                    <div class="benefits-icon-box mx-auto">
                                        <div class="benefits-icon d-flex align-items-center justify-content-center">
                                            <img src="../../assets/images/benefits-8.png" alt="Easy scaling" title="Easy scaling">
                                        </div>
                                    </div>
                                    <div class="benefits-name text-center px-2">Easy scaling</div>
                                </div>
                            </div>
                        </div>
                        <!-- box 8 end -->
                        <!-- box 9 start -->
                        <div class="col-12 col-lg-4 col-xl-4 col-md-6 col-sm-6 benefits-list mb-4">
                            <div class="benefits-box d-flex align-items-center justify-content-center">
                                <div class="benefits-cnt w-100">
                                    <div class="benefits-icon-box mx-auto">
                                        <div class="benefits-icon d-flex align-items-center justify-content-center">
                                            <img src="../../assets/images/benefits-9.png" alt="Low Maintenance Cost"
                                                title="Low Maintenance Cost">
                                        </div>
                                    </div>
                                    <div class="benefits-name text-center px-2">Low Maintenance Cost</div>
                                </div>
                            </div>
                        </div>
                        <!-- box 9 end -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 text-center mt-4 mt-lg-4 mt-xl-2 mt-md-4 mt-sm-4">
            <a routerLink="/features" (click)="scrollToTop()" class="common-btn">Show More</a>
        </div>
    </div>

</div>
<!-- Backend API Generation & Development Solution  End -->
<!-- backend table  start -->
<div class="backend-table-section pt-4 pb-4">
    <h2 class="px-3"><span class="light-text d-block">How API Maker Improves</span>Your Backend Development Process
    </h2>
    <div class="w-100 mt-4 mt-lg-4 mt-xl-5 mt-md-4 mt-sm-4">
        <div class="container">
            <div class="table-foment">
                <table>
                    <thead>
                        <tr>
                            <th>Traditional Backend <span class="d-block">Development Process</span></th>
                            <th>API Maker as Backend</th>
                            <th>API Product Benefits</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td data-label="In every backend project we have common repetitive work around 60%.">In every backend project we
                                have common repetitive work around 60%.</td>
                            <td data-label="No need for backend setup.">No need for backend setup.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">Need to create CRUD &
                                pagination APIs for almost every table.</td>
                            <td data-label="API Maker as Backend">Auto generated APIs.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">In agile development table
                                structure modification is very frequent, so need to change & test base APIs every
                                time.</td>
                            <td data-label="API Maker as Backend">Auto generated APIs.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">Need multiple high skilled
                                developers in backend project and team is also keeps changing.</td>
                            <td data-label="API Maker as Backend">Only JavaScript developer can easily work.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Money</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">Dev | Client | Tester, need to
                                wait for few minutes for latest code to be deployed.</td>
                            <td data-label="API Maker as Backend">Ctrl + S = Changes are saved Git pull button click
                                = Apply latest code within ~15 seconds.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">Bug fixing is hard because
                                developer’s development environment is different then production environment.</td>
                            <td data-label="API Maker as Backend">Both environments are same.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">Sometimes bugs are only
                                reproducible on servers because of data | configuration issues. They are very hard
                                to fix.</td>
                            <td data-label="API Maker as Backend">Dev can easily reproduce & fix on that specific
                                server.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">Need to create separate APIs
                                for web app and mobile apps for common operations.</td>
                            <td data-label="API Maker as Backend">Generated APIs changes their output based on
                                request.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">Developers needs few hours/days
                                to setup their local backend environment to start working on it.</td>
                            <td data-label="API Maker as Backend">No local setup required.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">After code change, need to
                                restart backend project to get latest output.</td>
                            <td data-label="API Maker as Backend">No local setup required.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">Backend developers needs API
                                testing tools like postman to test their APIs.</td>
                            <td data-label="API Maker as Backend">Inbuilt testing environment is given.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">DevOps skill required for
                                deployment pipeline setup and fix if issues comes.</td>
                            <td data-label="API Maker as Backend">API Maker provides deployment.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time + Money
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">Code deployment servers required.</td>
                            <td data-label="API Maker as Backend">No need.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time + Money</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">Testing efforts are high as we have more code.</td>
                            <td data-label="API Maker as Backend">Code is very less so testing efforts are also less.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Save Time + Money</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">Higher configuration servers required.</td>
                            <td data-label="API Maker as Backend">Runs on small servers.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                            class="fa-solid fa-circle-check check-icon"></i></span>Money</div>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Traditional Backend Development Process">While doing migrating of old code into newer technologies, We need almost same efforts because need to rewrite all code into newer programming language/frameworks.</td>
                            <td data-label="API Maker as Backend">Auto Generated APIs plays major role and reduces lots of errors in it.</td>
                            <td data-label="API Product Benefits">
                                <div class="table-button"><span class="icon-box"><i
                                    class="fa-solid fa-circle-check check-icon"></i></span>Save Time + Money</div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" class="text-end last-text-box"><strong>Use it and find more...</strong></td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>
<!-- backend table  End -->
<!-- Cloud  start -->
<div class="cloud-section">
    <div class="container">
        <div class="w-100 pt-4 pt-lg-4 pt-xl-5 pt-md-4 pt-sm-4 pb-4 pb-lg-4 pb-xl-5 pb-md-4 pb-sm-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div
                            class="col-xl-5 col-lg-5 col-md-12 col-sm-12 text-center text-xl-left text-lg-left text-md-center text-sm-center">
                            <div class="cloud-banner">
                                <img src="../../assets/images/cloud-provider-left.png" alt="Cloud Provider" title="Cloud Provider">
                            </div>
                        </div>
                        <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                            <h2>Deploy<span class="light-text"> API Maker</span> On Any Cloud Provider </h2>
                            <div class="cloud-cnt mt-3 mb-4">Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                                ever since </div>
                            <div
                                class="w-100 d-flex flex-wrap justify-content-center justify-content-xl-start justify-content-lg-start justify-content-md-center justify-content-sm-center">
                                <!--  start -->
                                <div class="cloud-provider mt-2 me-3 mb-2">
                                    <div class="cloud-icon-box d-flex align-items-center justify-content-center">
                                        <img src="../../assets/images/cloud-provider-1.png" alt="AWS" title="AWS">
                                    </div>
                                    <div class="cloud-name-box">AWS</div>
                                </div>
                                <!-- End -->
                                <!--  start -->
                                <div class="cloud-provider mt-2 me-3 mb-2">
                                    <div class="cloud-icon-box d-flex align-items-center justify-content-center">
                                        <img src="../../assets/images/cloud-provider-2.png" alt="Azure" title="Azure">
                                    </div>
                                    <div class="cloud-name-box">Azure</div>
                                </div>
                                <!-- End -->
                                <!--  start -->
                                <div class="cloud-provider mt-2 me-3 mb-2">
                                    <div class="cloud-icon-box d-flex align-items-center justify-content-center">
                                        <img src="../../assets/images/cloud-provider-3.png" alt="Google Cloud" title="Google Cloud">
                                    </div>
                                    <div class="cloud-name-box">Google Cloud</div>
                                </div>
                                <!-- End -->
                                <!--  start -->
                                <div class="cloud-provider mt-2 me-3 mb-2">
                                    <div class="cloud-icon-box d-flex align-items-center justify-content-center">
                                        <img src="../../assets/images/cloud-provider-4.png" alt="Linode" title="Linode">
                                    </div>
                                    <div class="cloud-name-box">Linode</div>
                                </div>
                                <!-- End -->
                                <!--  start -->
                                <div class="cloud-provider mt-2 me-3 mb-2">
                                    <div class="cloud-icon-box d-flex align-items-center justify-content-center">
                                        <img src="../../assets/images/cloud-provider-5.png" alt="Your Data Centre"
                                            title="Your Data Centre">
                                    </div>
                                    <div class="cloud-name-box">Your Data Centre</div>
                                </div>
                                <!-- End -->
                                <!--  start -->
                                <div class="cloud-provider mt-2 me-3 mb-2">
                                    <div class="cloud-icon-box d-flex align-items-center justify-content-center">
                                        <img src="../../assets/images/cloud-provider-6.png" alt="Any Other Cloud Providers"
                                            title="Any Other Cloud Providers">
                                    </div>
                                    <div class="cloud-name-box">Any Other Cloud Providers</div>
                                </div>
                                <!-- End -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Cloud  End -->
<!-- Benchmarking  start -->
<div class="benchmark-section pt-4 pb-4">
    <div class="container">
        <h2><span class="light-text"> API Maker ®</span> Benchmarking</h2>
        <div class="w-100 mt-4 mt-lg-4 mt-xl-5 mt-md-4 mt-sm-4 pb-2">
            <div class="col-md-12 text-center benchmark-cnt">These benchmarks are taken on Linode shared VPS
                servers. You can deploy API Maker on any cloud provider.</div>
        </div>
        <h3 class="mt-2 mt-lg-3 mt-xl-3 mt-md-3 mt-sm-3"><span class="light-text">Used Configuration for</span>
            Benchmarking</h3>
        <div class="w-100 d-flex flex-wrap gap-3 justify-content-center mt-3 mb-4">
            <div class="benchmarking-chip-box d-flex align-items-center">Data
                size = 10 Rows & 10 Columns</div>
            <div class="benchmarking-chip-box d-flex align-items-center">Database
                = Mongodb 6</div>
            <div class="benchmarking-chip-box d-flex align-items-center">Cloud
                Provider = Linode</div>
            <div class="benchmark-check d-flex align-items-center">
                <a href="https://apimaker.dev/json/benchmark_dataset.json" target="_blank" class="common-btn">Benchmark
                    Data</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 benchmark-list mt-4 pt-3">
                <div class="row">
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <!-- start -->
                        <div class="benchmark-box">
                            <div class="benchmark-icon-box d-flex">
                                <div class="benchmark-icon d-flex align-items-center justify-content-center"><img
                                        src="../../assets/images/benchmark-icon.png" alt="Benchmark" title="Benchmark"></div>
                            </div>
                            <div class="benchmark-title">Json Requests Summary</div>
                            <div class="benchmark-sub-title mb-3">47k requests in 60.04s, 105 MB read</div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">Speed</div>
                                <div class="benchmark-cnt-right">788 Req/sec</div>
                            </div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">CPUs</div>
                                <div class="benchmark-cnt-right">1</div>
                            </div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">RAM</div>
                                <div class="benchmark-cnt-right">1GB</div>
                            </div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">Bandwidth</div>
                                <div class="benchmark-cnt-right">1TB</div>
                            </div>
                        </div>
                        <!-- End -->
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <!-- start -->
                        <div class="benchmark-box">
                            <div class="benchmark-icon-box d-flex">
                                <div class="benchmark-icon d-flex align-items-center justify-content-center"><img
                                        src="../../assets/images/benchmark-icon.png" alt="Benchmark" title="Benchmark"></div>
                            </div>
                            <div class="benchmark-title">Json Requests Summary</div>
                            <div class="benchmark-sub-title mb-3">88k requests in 60.03s, 195 MB read</div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">Speed</div>
                                <div class="benchmark-cnt-right">1458 Req/sec</div>
                            </div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">CPUs</div>
                                <div class="benchmark-cnt-right">2</div>
                            </div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">RAM</div>
                                <div class="benchmark-cnt-right">4GB</div>
                            </div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">Bandwidth</div>
                                <div class="benchmark-cnt-right">1TB</div>
                            </div>
                        </div>
                        <!-- End -->
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <!-- start -->
                        <div class="benchmark-box">
                            <div class="benchmark-icon-box d-flex">
                                <div class="benchmark-icon d-flex align-items-center justify-content-center"><img
                                        src="../../assets/images/benchmark-icon.png" alt="Benchmark" title="Benchmark"></div>
                            </div>
                            <div class="benchmark-title">Json Requests Summary</div>
                            <div class="benchmark-sub-title mb-3">166k requests  in 60.04s, 368 MB read</div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">Speed</div>
                                <div class="benchmark-cnt-right">2759 Req/sec</div>
                            </div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">CPUs</div>
                                <div class="benchmark-cnt-right">4</div>
                            </div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">RAM</div>
                                <div class="benchmark-cnt-right">8GB</div>
                            </div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">Bandwidth</div>
                                <div class="benchmark-cnt-right">5TB</div>
                            </div>
                        </div>
                        <!-- End -->
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <!-- start -->
                        <div class="benchmark-box">
                            <div class="benchmark-icon-box d-flex">
                                <div class="benchmark-icon d-flex align-items-center justify-content-center"><img
                                        src="../../assets/images/benchmark-icon.png" alt="Benchmark" title="Benchmark"></div>
                            </div>
                            <div class="benchmark-title">Json Requests Summary</div>
                            <div class="benchmark-sub-title mb-3">166k requests  in 60.04s, 368 MB read</div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">Speed</div>
                                <div class="benchmark-cnt-right">2759 Req/sec</div>
                            </div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">CPUs</div>
                                <div class="benchmark-cnt-right">4</div>
                            </div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">RAM</div>
                                <div class="benchmark-cnt-right">8GB</div>
                            </div>
                            <div class="benchmark-cnt-list d-flex flex-nowrap">
                                <div class="benchmark-cnt-left">Bandwidth</div>
                                <div class="benchmark-cnt-right">5TB</div>
                            </div>
                        </div>
                        <!-- End -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Benchmarking  End -->
<!-- Google Review start -->
<div class="review-section pt-4 pb-4">
    <div class="container">
        <h2>Google <span class="light-text">Review</span></h2>
        <div class="w-100 mt-4 mt-lg-4 mt-xl-5 mt-md-4 mt-sm-4">
            <div id="owl-carousel3" class="owl-carousel  owl-theme">
                <!-- 1 review start -->
                <div class="review-box">
                    <!-- start -->
                    <div class="w-100 review-top d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-photo"><img src="../../assets/images/shani-patel-review.png" alt="Shani Patel Review"
                                title="Shani Patel Review"></div>
                        <div class="review-right ms-3">
                            <div class="review-name">Shani Patel</div>
                            <div class="review-company">Fullstack Developer</div>
                            <div class="review-position"></div>
                        </div>
                    </div>
                    <!-- End -->
                    <!-- start -->
                    <div class="w-100 mt-3 review-time d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-star">
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                        </div>
                        <div class="review-month">4 months ago</div>
                    </div>
                    <!-- End -->
                    <div class="w-100 review-text mt-3">I recently had the opportunity to explore API Maker, and I
                        must say, I'm thoroughly impressed. Their product simplifies the process of generating APIs,
                        offering a seamless experience from start to finish. One standout feature is its ability to
                        auto-generate APIs for 8 different database types. This level of flexibility ensures
                        compatibility with a wide range of systems, making integration effortless. The user
                        interface is intuitive and user-friendly, making it easy to navigate even for those new to
                        API development. Overall, I'm highly satisfied with API Maker's performance and would
                        recommend it to anyone looking for a reliable solution for API generation.</div>
                </div>
                <!-- 1 review End -->
                <!-- 2 review start -->
                <div class="review-box">
                    <!-- start -->
                    <div class="w-100 review-top d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-photo"><img src="../../assets/images/sean-mcgovern-review.png" alt="Sean Mcgovern Review"
                                title="Sean Mcgovern Review"></div>
                        <div class="review-right ms-3">
                            <div class="review-name">Sean Mcgovern</div>
                            <div class="review-company">DSouth Taxis, Ireland</div>
                            <div class="review-position">Director</div>
                        </div>
                    </div>
                    <!-- End -->
                    <!-- start -->
                    <div class="w-100 mt-3 review-time d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-star">
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                        </div>
                        <div class="review-month">4 months ago</div>
                    </div>
                    <!-- End -->
                    <div class="w-100 review-text mt-3">I used in my two application and it works very smooth,
                        custom APIs are so much powerful that I didn't expect. I recomend all freelancer and IT
                        companies use this and enjoy the effort less work in backend.</div>
                </div>
                <!-- 2 review End -->
                <!-- 3 review start -->
                <div class="review-box">
                    <!-- start -->
                    <div class="w-100 review-top d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-photo"><img src="../../assets/images/bhavesh-patel-review.jpg" alt="Bhavesh Patel Review"
                                title="Bhavesh Patel Review"></div>
                        <div class="review-right ms-3">
                            <div class="review-name">Bhavesh Patel</div>
                            <div class="review-company">Apollo Softwares</div>
                            <div class="review-position">Director</div>
                        </div>
                    </div>
                    <!-- End -->
                    <!-- start -->
                    <div class="w-100 mt-3 review-time d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-star">
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                        </div>
                        <div class="review-month">4 months ago</div>
                    </div>
                    <!-- End -->
                    <div class="w-100 review-text mt-3">I used in my two application and it works very smooth,
                        custom APIs are so much powerful that I didn't expect. I recomend all freelancer and IT
                        companies use this and enjoy the effort less work in backend.</div>
                </div>
                <!-- 3 review End -->
                <!-- 4 review start -->
                <div class="review-box">
                    <!-- start -->
                    <div class="w-100 review-top d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-photo"><img src="../../assets/images/tausifali-saiyed-review.png"
                                alt="Tausifali Saiyed Review" title="Tausifali Saiyed Review"></div>
                        <div class="review-right ms-3">
                            <div class="review-name">Tausifali Saiyed</div>
                            <div class="review-company">Kuntec Online Services Pvt. Ltd.</div>
                            <div class="review-position">Director</div>
                        </div>
                    </div>
                    <!-- End -->
                    <!-- start -->
                    <div class="w-100 mt-3 review-time d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-star">
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                        </div>
                        <div class="review-month">5 months ago</div>
                    </div>
                    <!-- End -->
                    <div class="w-100 review-text mt-3">Great Team with quality of product and services. API Maker
                        is one of the game changer for all Developers.</div>
                </div>
                <!-- 4 review End -->
                <!-- 5 review start -->
                <div class="review-box">
                    <!-- start -->
                    <div class="w-100 review-top d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-photo"><img src="../../assets/images/vaidik-patel-review.png" alt="Vaidik Patel Review"
                                title="Vaidik Patel Review"></div>
                        <div class="review-right ms-3">
                            <div class="review-name">Vaidik Patel</div>
                            <div class="review-company">Data scientist</div>
                            <div class="review-position"></div>
                        </div>
                    </div>
                    <!-- End -->
                    <!-- start -->
                    <div class="w-100 mt-3 review-time d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-star">
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                        </div>
                        <div class="review-month">4 months ago</div>
                    </div>
                    <!-- End -->
                    <div class="w-100 review-text mt-3">Great Product and really helpful for generating custom
                        API's. Easy to use. Thank you for your great support.</div>
                </div>
                <!-- 5 review End -->
                <!-- 6 review start -->
                <div class="review-box">
                    <!-- start -->
                    <div class="w-100 review-top d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-photo"><img src="../../assets/images/jigar-goswami-review.png" alt="Jigar Goswami Review"
                                title="Jigar Goswami Review"></div>
                        <div class="review-right ms-3">
                            <div class="review-name">Jigar Goswami</div>
                            <div class="review-company">Krishiva Tech</div>
                            <div class="review-position">Director</div>
                        </div>
                    </div>
                    <!-- End -->
                    <!-- start -->
                    <div class="w-100 mt-3 review-time d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-star">
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                        </div>
                        <div class="review-month">9 months ago</div>
                    </div>
                    <!-- End -->
                    <div class="w-100 review-text mt-3">
                        <div class="w-100">Stop relying on traditional backend tools.</div>
                        <div class="w-100 mt-2">Introducing API Maker: Your ultimate all-in-one backend
                            development solution:</div>
                        <div class="w-100 mt-2">API Maker > 5 x (Firebase + Supabase + Appwrite +
                            GraphQL)</div>
                    </div>
                </div>
                <!-- 6 review End -->
                <!-- 7 review start -->
                <div class="review-box">
                    <!-- start -->
                    <div class="w-100 review-top d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-photo"><img src="../../assets/images/gaurav-patel-review.png" alt="Gaurav Patel Review"
                                title="Gaurav Patel Review"></div>
                        <div class="review-right ms-3">
                            <div class="review-name">Gaurav Patel</div>
                            <div class="review-company">SAVA Info Systems Pvt. Ltd.</div>
                            <div class="review-position"></div>
                        </div>
                    </div>
                    <!-- End -->
                    <!-- start -->
                    <div class="w-100 mt-3 review-time d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-star">
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                        </div>
                        <div class="review-month">5 months ago</div>
                    </div>
                    <!-- End -->
                    <div class="w-100 review-text mt-3">
                        <div class="w-100">Revolutionary technology for backend development.</div>
                        <div class="w-100 mt-2">Using in my private projects.</div>
                        <div class="w-100 mt-2">Thank you API Maker Team.</div>
                    </div>
                </div>
                <!-- 7 review End -->
                <!-- 8 review start -->
                <div class="review-box">
                    <!-- start -->
                    <div class="w-100 review-top d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-photo"><img src="../../assets/images/pratik-santoki-review.png"
                                alt="Pratik Santoki Review" title="Pratik Santoki Review"></div>
                        <div class="review-right ms-3">
                            <div class="review-name">Pratik Santoki</div>
                            <div class="review-company">FiveStake Software Pvt. Ltd.</div>
                            <div class="review-position">CEO & Founder</div>
                        </div>
                    </div>
                    <!-- End -->
                    <!-- start -->
                    <div class="w-100 mt-3 review-time d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-star">
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                        </div>
                        <div class="review-month">4 months ago</div>
                    </div>
                    <!-- End -->
                    <div class="w-100 review-text mt-3">
                        <div class="w-100">Great product...Very helpful to overcome repetitive tasks.</div>
                        <div class="w-100 mt-2">Highly recommended !!!</div>
                    </div>
                </div>
                <!-- 8 review End -->
                <!-- 9 review start -->
                <div class="review-box">
                    <!-- start -->
                    <div class="w-100 review-top d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-photo"><img src="../../assets/images/3-way technologies-review.png"
                                alt="3 Way Technologies Review" title="3 Way Technologies Review"></div>
                        <div class="review-right ms-3">
                            <div class="review-name">3 Way Technologies</div>
                            <div class="review-company">Paras Patel</div>
                            <div class="review-position">Director</div>
                        </div>
                    </div>
                    <!-- End -->
                    <!-- start -->
                    <div class="w-100 mt-3 review-time d-flex flex-nowrap align-items-center justify-content-start">
                        <div class="review-star">
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                            <i class="fa-solid fa-star star-icon"></i>
                        </div>
                        <div class="review-month">9 months ago</div>
                    </div>
                    <!-- End -->
                    <div class="w-100 review-text mt-3">Very good tool for creating the API</div>
                </div>
                <!-- 9 review End -->
            </div>

        </div>
    </div>
</div>
<!-- Google Review End -->
<!-- faq start -->
<div class="faq-section pt-4 pb-4">
    <div class="container">
        <h2><span class="light-text">Frequently</span> Asked Questions </h2>
        <div class="w-100 mt-4 mt-lg-4 mt-xl-5 mt-md-4 mt-sm-4 pb-4">
            <div class="faq-accordion-list">
                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                Why API Maker is free?
                            </button>
                        </h2>
                        <div id="flush-collapse1" class="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <div class="common-listing">
                                    <ul>
                                        <li>We believe backend development framework should be 100% free to use for
                                            everyone.</li>
                                        <li>Very useful to small businesses and students who cannot afford paid
                                            software.</li>
                                        <li>Because it is free, our marketing efforts will be very much less, and we
                                            can easily spread it over developer community.</li>
                                        <li>We are generating revenue by providing support to enterprises.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                Will API Maker be free in future?
                            </button>
                        </h2>
                        <div id="flush-collapse2" class="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <div class="common-listing">
                                    <ul>
                                        <li>Yes, That guarantee is indirectly given by Microsoft & Facebook &
                                            Docker.</li>
                                        <li>API Maker is distributed to its users via NPM & Yarn package managers
                                            and once we publish any package in NPM, it will stay in NPM forever. can
                                            not remove it from NPM even if we want.</li>
                                        <li>NPM is acquired by GitHub in 2020 and GitHub is acquired by Microsoft.
                                        </li>
                                        <li>Yarn is Node.js package manager from Facebook.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                Why not open source?
                            </button>
                        </h2>
                        <div id="flush-collapse3" class="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <div class="common-listing">
                                    <ul>
                                        <li>We do not want any modifications or distributions of API Maker other
                                            than company itself, so it will protect the intellectual property rights
                                            of API Maker.</li>
                                        <li>Because the source code is not publicly available and therefore less
                                            exposed to potential attackers.</li>
                                        <li>We are generating revenue by providing support to enterprises.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                Developer | Client | Company : If we use API Maker, we will be dependent on it
                                right?
                            </button>
                        </h2>
                        <div id="flush-collapse4" class="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <div class="common-listing">
                                    <ul>
                                        <li> No. API Maker is 100% free.</li>
                                        <li>Other than generated APIs & it’s feature, it is just pure JavaScript, so
                                            any JS developer can work on it.
                                            <ul>
                                                <li>We have done lots of documentation.</li>
                                                <li>We have tutorial videos.</li>
                                            </ul>
                                        </li>
                                        <li>You will install it on your local/servers, so you will have full control
                                            over it.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center mt-4 mt-lg-4 mt-xl-4 mt-md-4 mt-sm-4" >
                <a  routerLink="/faq" (click)="scrollToTop()" class="common-btn">Show More</a>
            </div>
        </div>
    </div>
</div>
<!-- faq End -->
<app-footer></app-footer>
