<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker® Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-features.png" alt="API Maker® Features"
                                title="API Maker® Features">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<!-- inner start -->
<div class="inner-mid-section">
    <div class="container">
        <h2 class="text-center">Product <span class="light-text">API Maker</span></h2>
        <div class="row">
            <div class="col-md-12 grid gap-5 features-list-section mt-5">
                <div class="row">
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-auto-api.png"
                                        alt="Powerful Auto Generated APIs" title="Powerful Auto Generated APIs">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Powerful Auto Generated APIs</div>
                                <div class="feature-cnt-short">Connect the Database and enjoy autogenerated APIs.
                                    Below is the Schema APIs and Generated APIs list.</div>
                                <div class="feature-cnt-more"><a routerLink="/auto-generat">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-database-support.png"
                                        alt="8 Database Support Out Of The Box"
                                        title="8 Database Support Out Of The Box">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">8 Database Support Out Of The Box</div>
                                <div class="feature-cnt-short">This deep populate feature is supported in all types
                                    of databases and supported in all APIs, which returns data.</div>
                                <div class="feature-cnt-more"><a routerLink="/database-support">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-custom-api.png" alt="Custom APIs Development"
                                        title="Custom APIs Development">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Custom APIs Development</div>
                                <div class="feature-cnt-short">We can quickly write & test custom APIs to handle
                                    complex business scenarios.</div>
                                <div class="feature-cnt-more"><a routerLink="/custom-api">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-third-party.png" alt="Third Party APIs"
                                        title="Third Party APIs">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Third Party APIs</div>
                                <div class="feature-cnt-short">Install ready made Third Party APIs with code from
                                    API Maker Store</div>
                                <div class="feature-cnt-more"><a routerLink="/third-party-api">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-systems.png" alt="Inbuilt System APIs"
                                        title="Inbuilt System APIs">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Inbuilt System APIs</div>
                                <div class="feature-cnt-short">API Maker® provides intellectual system APIs to do
                                    your work smartly.</div>
                                <div class="feature-cnt-more"><a routerLink="/inbuilt-system">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-schedulers.png" alt="Schedulers"
                                        title="Schedulers">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Schedulers</div>
                                <div class="feature-cnt-short">A centralized place for all our secret keys.</div>
                                <div class="feature-cnt-more"><a routerLink="/schedule">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-websocket.png" alt="Events Management"
                                        title="Events Management">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Events Management</div>
                                <div class="feature-cnt-short">API Maker® supports, system events, custom events & N
                                    number of listeners.</div>
                                <div class="feature-cnt-more"><a routerLink="/events-management">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-secret.png" alt="Secrets Management"
                                        title="Secrets Management">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Secrets Management</div>
                                <div class="feature-cnt-short">A centralized place for all our secret keys.</div>
                                <div class="feature-cnt-more"><a routerLink="/secrets-management">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-signon.png"
                                        alt="Single sign On For Organization" title="Single sign On For Organization">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Single sign On For Organization</div>
                                <div class="feature-cnt-short">Single sign-on for organization.</div>
                                <div class="feature-cnt-more"><a routerLink="/single-sign">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-sandbox.png" alt="Secure Sandbox"
                                        title="Secure Sandbox">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Secure Sandbox</div>
                                <div class="feature-cnt-short">Execute all code in a secure and well-managed sandbox
                                    environment.</div>
                                <div class="feature-cnt-more"><a routerLink="/secure-sandbox">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-testing.png" alt="Inbuilt Testing Framework"
                                        title="Inbuilt Testing Framework">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Inbuilt Testing Framework</div>
                                <div class="feature-cnt-short">Powerful inbuilt testing framework provided for
                                    business logic testing.</div>
                                <div class="feature-cnt-more"><a routerLink="/inbuilt-testing">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-redis.png" alt="Automatic Caching In APIs"
                                        title="Automatic Caching In APIs">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Automatic Caching In APIs</div>
                                <div class="feature-cnt-short">Automatic caching management on database tables,
                                    custom APIs, system APIs, and third-party APIs.</div>
                                <div class="feature-cnt-more"><a routerLink="/automatic-caching">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-log.png" alt="API Logging"
                                        title="API Logging">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">API Logging</div>
                                <div class="feature-cnt-short">Profile-based logging for everything</div>
                                <div class="feature-cnt-more"><a routerLink="/logging-api">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-db-m.png" alt="Database Migrations"
                                        title="Database Migrations">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Database Migrations</div>
                                <div class="feature-cnt-short">Automatic caching management on database tables,
                                    custom APIs, system APIs, and third-party APIs.</div>
                                <div class="feature-cnt-more"><a routerLink="/database-migrations">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-github.png" alt="Git Pull Deployment"
                                        title="Git Pull Deployment">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Git Pull Is Deployment</div>
                                <div class="feature-cnt-short">Create branch ex: feature_1 from [DEV/QA/UAT/PROD]
                                    within API Maker.</div>
                                <div class="feature-cnt-more"><a routerLink="/git-deployment">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-dev-ac.png" alt="Developer Accounts"
                                        title="Developer Accounts">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Developer Accounts</div>
                                <div class="feature-cnt-short">Collaborative development on the dev server itself of
                                    1 CPU Core.</div>
                                <div class="feature-cnt-more"><a routerLink="/developer-accounts">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-json-file.png"
                                        alt=" JSON/YAML/XML Output Support" title=" JSON/YAML/XML Output Support">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title"> JSON/YAML/XML Output Support</div>
                                <div class="feature-cnt-short">Get output in JSON/YAML/XML from any API response.
                                </div>
                                <div class="feature-cnt-more"><a routerLink="/get-output">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-low-memory.png" alt="Low memory footprint"
                                        title="Low memory footprint">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Low Memory Footprint</div>
                                <div class="feature-cnt-short">API Maker process takes around ~140MB, (including all
                                    native code) so we have lots of memory available on server of 1GB RAM, to do
                                    amazing tasks.</div>
                                <div class="feature-cnt-more"><a routerLink="/low-memory-footprint">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-access-management.png"
                                        alt="Access Management Of APIs" title="Access Management Of APIs">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Access Management Of APIs</div>
                                <div class="feature-cnt-short">API Maker has an effective permission model for API
                                    access</div>
                                <div class="feature-cnt-more"><a routerLink="/access-management">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-i18n.png" alt="Internationalization"
                                        title="Internationalization">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Internationalization</div>
                                <div class="feature-cnt-short">Multiple languages support in error messages.</div>
                                <div class="feature-cnt-more"><a routerLink="/internationalization">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                    <!-- features box start -->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="features-box rounded-xl shadow-sm">
                            <div class="feature-icon-box d-flex justify-content-center align-items-center">
                                <div class="icon-center">
                                    <img src="../../assets/images/features-monitoring.png" alt="Monitoring"
                                        title="Monitoring">
                                </div>
                            </div>
                            <div class="feature-cnt-box">
                                <div class="feature-cnt-title">Monitoring</div>
                                <div class="feature-cnt-short">Different Dashboard to monitor APIs & Servers.</div>
                                <div class="feature-cnt-more"><a routerLink="/monitoring">Read More <span
                                            class="more-arrow"><i class="fas fa-arrow-right"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                    <!-- features box End -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- inner End -->
<!-- Cloud  start -->
<div class="cloud-section">
    <div class="container">
        <div class="w-100 pt-4 pt-lg-4 pt-xl-5 pt-md-4 pt-sm-4 pb-4 pb-lg-4 pb-xl-5 pb-md-4 pb-sm-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div
                            class="col-xl-5 col-lg-5 col-md-12 col-sm-12 text-center text-xl-left text-lg-left text-md-center text-sm-center">
                            <div class="cloud-banner">
                                <img src="../../assets/images/cloud-provider-left.png" alt="Cloud Provider" title="Cloud Provider">
                            </div>
                        </div>
                        <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                            <h2>Deploy<span class="light-text"> API Maker</span> On Any Cloud Provider </h2>
                            <div class="cloud-cnt mt-3 mb-4">Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                                ever since </div>
                            <div
                                class="w-100 d-flex flex-wrap justify-content-center justify-content-xl-start justify-content-lg-start justify-content-md-center justify-content-sm-center">
                                <!--  start -->
                                <div class="cloud-provider mt-2 me-3 mb-2">
                                    <div class="cloud-icon-box d-flex align-items-center justify-content-center">
                                        <img src="../../assets/images/cloud-provider-1.png" alt="AWS" title="AWS">
                                    </div>
                                    <div class="cloud-name-box">AWS</div>
                                </div>
                                <!-- End -->
                                <!--  start -->
                                <div class="cloud-provider mt-2 me-3 mb-2">
                                    <div class="cloud-icon-box d-flex align-items-center justify-content-center">
                                        <img src="../../assets/images/cloud-provider-2.png" title="Azure" alt="Azure">
                                    </div>
                                    <div class="cloud-name-box">Azure</div>
                                </div>
                                <!-- End -->
                                <!--  start -->
                                <div class="cloud-provider mt-2 me-3 mb-2">
                                    <div class="cloud-icon-box d-flex align-items-center justify-content-center">
                                        <img src="../../assets/images/cloud-provider-3.png" alt="Google Cloud" title="Google Cloud">
                                    </div>
                                    <div class="cloud-name-box">Google Cloud</div>
                                </div>
                                <!-- End -->
                                <!--  start -->
                                <div class="cloud-provider mt-2 me-3 mb-2">
                                    <div class="cloud-icon-box d-flex align-items-center justify-content-center">
                                        <img src="../../assets/images/cloud-provider-4.png" title="Linode" alt="Linode">
                                    </div>
                                    <div class="cloud-name-box">Linode</div>
                                </div>
                                <!-- End -->
                                <!--  start -->
                                <div class="cloud-provider mt-2 me-3 mb-2">
                                    <div class="cloud-icon-box d-flex align-items-center justify-content-center">
                                        <img src="../../assets/images/cloud-provider-5.png" alt="Data Centre" title="Data Centre">
                                    </div>
                                    <div class="cloud-name-box">Your Data Centre</div>
                                </div>
                                <!-- End -->
                                <!--  start -->
                                <div class="cloud-provider mt-2 me-3 mb-2">
                                    <div class="cloud-icon-box d-flex align-items-center justify-content-center">
                                        <img src="../../assets/images/cloud-provider-6.png" alt="Other Cloud Providers" title="Other Cloud Providers">
                                    </div>
                                    <div class="cloud-name-box">Any Other Cloud Providers</div>
                                </div>
                                <!-- End -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Cloud  End -->

<app-footer></app-footer>
