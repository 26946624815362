import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { environment } from './environments/environment';
import { FaqComponent } from './faq/faq.component';
import { AccessManagementComponent } from './features/access-management/access-management.component';
import { AutoGeneratComponent } from './features/auto-generat/auto-generat.component';
import { AutomaticCachingComponent } from './features/automatic-caching/automatic-caching.component';
import { CustomApiComponent } from './features/custom-api/custom-api.component';
import { DatabaseMigrationsComponent } from './features/database-migrations/database-migrations.component';
import { DatabaseSupportComponent } from './features/database-support/database-support.component';
import { DeveloperAccountsComponent } from './features/developer-accounts/developer-accounts.component';
import { EventsManagementComponent } from './features/events-management/events-management.component';
import { FeaturesSidebarComponent } from './features/features-sidebar/features-sidebar.component';
import { FeaturesComponent } from './features/features.component';
import { GetOutputComponent } from './features/get-output/get-output.component';
import { GitDeploymentComponent } from './features/git-deployment/git-deployment.component';
import { InbuiltSystemComponent } from './features/inbuilt-system/inbuilt-system.component';
import { InbuiltTestingComponent } from './features/inbuilt-testing/inbuilt-testing.component';
import { InternationalizationComponent } from './features/internationalization/internationalization.component';
import { LoggingApiComponent } from './features/logging-api/logging-api.component';
import { LowMemoryFootprintComponent } from './features/low-memory-footprint/low-memory-footprint.component';
import { MonitoringComponent } from './features/monitoring/monitoring.component';
import { ScheduleComponent } from './features/schedule/schedule.component';
import { SecretsManagementComponent } from './features/secrets-management/secrets-management.component';
import { SecureSandboxComponent } from './features/secure-sandbox/secure-sandbox.component';
import { SingleSignComponent } from './features/single-sign/single-sign.component';
import { ThirdPartyApiComponent } from './features/third-party-api/third-party-api.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PlatfromtreeComponent } from './platfromtree/platfromtree.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { VideosComponent } from './videos/videos.component';


@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterLink,
        RouterOutlet,
        RouterLinkActive,
        NavbarComponent,
        FooterComponent,
        AboutComponent,
        VideosComponent,
        CommonModule,
        FaqComponent,
        FaqComponent,
        PrivacyComponent,
        FeaturesComponent,
        ContactComponent,
        PlatfromtreeComponent,
        AutoGeneratComponent,
        FeaturesSidebarComponent,
        DatabaseSupportComponent,
        CustomApiComponent,
        ThirdPartyApiComponent,
        InbuiltSystemComponent,
        ScheduleComponent,
        EventsManagementComponent,
        SecretsManagementComponent,
        SingleSignComponent,
        SecureSandboxComponent,
        InbuiltTestingComponent,
        AutomaticCachingComponent,
        LoggingApiComponent,
        DatabaseMigrationsComponent,
        GitDeploymentComponent,
        DeveloperAccountsComponent,
        GetOutputComponent,
        LowMemoryFootprintComponent,
        AccessManagementComponent,
        InternationalizationComponent,
        MonitoringComponent,

    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    title = 'API Maker';

    constructor(
        public router: Router
    ) {
        if (environment.reloadPageOnNavigate) {
            // Reload every page on navigation.
            this.router.events.pipe(
                filter(value => value instanceof NavigationEnd),
            ).subscribe(event => {
                if ((<any>event).id === 1) return;
                window.location.reload();
            });
        }
    }
}
