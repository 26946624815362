import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { NgModule } from '@angular/core';
import { VideosComponent } from './videos/videos.component';
import { FaqComponent } from './faq/faq.component';
import { TeamsComponent } from './teams/teams.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { FeaturesComponent } from './features/features.component';
import { ContactComponent } from './contact/contact.component';
import { PlatfromtreeComponent } from './platfromtree/platfromtree.component';
import { AutoGeneratComponent } from './features/auto-generat/auto-generat.component';
import { DatabaseSupportComponent } from './features/database-support/database-support.component';
import { CustomApiComponent } from './features/custom-api/custom-api.component';
import { ThirdPartyApiComponent } from './features/third-party-api/third-party-api.component';
import { InbuiltSystemComponent } from './features/inbuilt-system/inbuilt-system.component';
import { ScheduleComponent } from './features/schedule/schedule.component';
import { EventsManagementComponent } from './features/events-management/events-management.component';
import { SecretsManagementComponent } from './features/secrets-management/secrets-management.component';
import { SingleSignComponent } from './features/single-sign/single-sign.component';
import { SecureSandboxComponent } from './features/secure-sandbox/secure-sandbox.component';
import { InbuiltTestingComponent } from './features/inbuilt-testing/inbuilt-testing.component';
import { AutomaticCachingComponent } from './features/automatic-caching/automatic-caching.component';
import { LoggingApiComponent } from './features/logging-api/logging-api.component';
import { DatabaseMigrationsComponent } from './features/database-migrations/database-migrations.component';
import { GitDeploymentComponent } from './features/git-deployment/git-deployment.component';
import { DeveloperAccountsComponent } from './features/developer-accounts/developer-accounts.component';
import { GetOutputComponent } from './features/get-output/get-output.component';
import { LowMemoryFootprintComponent } from './features/low-memory-footprint/low-memory-footprint.component';
import { AccessManagementComponent } from './features/access-management/access-management.component';
import { InternationalizationComponent } from './features/internationalization/internationalization.component';
import { MonitoringComponent } from './features/monitoring/monitoring.component';


export const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'video', component: VideosComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'features', component: FeaturesComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'tearms', component: TeamsComponent },
    { path: 'auto-generat', component: AutoGeneratComponent },
    { path: 'database-support', component: DatabaseSupportComponent },
    { path: 'custom-api', component: CustomApiComponent },
    { path: 'third-party-api', component: ThirdPartyApiComponent },
    { path: 'inbuilt-system', component: InbuiltSystemComponent },
    { path: 'schedule', component: ScheduleComponent },
    { path: 'events-management', component: EventsManagementComponent },
    { path: 'secrets-management', component: SecretsManagementComponent },
    { path: 'single-sign', component: SingleSignComponent },
    { path: 'secure-sandbox', component: SecureSandboxComponent },
    { path: 'inbuilt-testing', component: InbuiltTestingComponent },
    { path: 'automatic-caching', component: AutomaticCachingComponent },
    { path: 'privacy-policy', component: PrivacyComponent },
    { path: 'platform-tree', component: PlatfromtreeComponent },
    { path: 'logging-api', component: LoggingApiComponent },
    { path: 'database-migrations', component: DatabaseMigrationsComponent },
    { path: 'git-deployment', component: GitDeploymentComponent },
    { path: 'developer-accounts', component: DeveloperAccountsComponent },
    { path: 'get-output', component: GetOutputComponent },
    { path: 'low-memory-footprint', component: LowMemoryFootprintComponent },
    { path: 'access-management', component: AccessManagementComponent },
    { path: 'internationalization', component: InternationalizationComponent },
    { path: 'monitoring', component: MonitoringComponent },
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }