<!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->

<div #stickyMenu class="sidebar">
    <div class="sidebar-right" [class.sticky]="sticky">
        <h3 class="mb-2">Features <span class="light-text">List</span></h3>
        <ul class="pt-2 pb-2">
            <li><a routerLink="/auto-generat">Powerful Auto Generated APIs</a></li>
            <li><a routerLink="/database-support">8 Database support out of the box</a></li>
            <li><a routerLink="/custom-api">Custom APIs Development</a></li>
            <li><a routerLink="/third-party-api">Third Party APIs</a></li>
            <li><a routerLink="/inbuilt-system">Inbuilt System APIs</a></li>
            <li><a routerLink="/schedule">Schedulers</a></li>
            <li><a routerLink="/events-management">Events Management</a></li>
            <li><a routerLink="/secrets-management">Secrets Management</a></li>
            <li><a routerLink="/single-sign">Single sign On For Organization</a></li>
            <li><a routerLink="/secure-sandbox">Secure Sandbox</a></li>
            <li><a routerLink="/inbuilt-testing">Inbuilt Testing Framework</a></li>
            <li><a routerLink="/automatic-caching">Automatic Caching In APIs</a></li>
            <li><a routerLink="/logging-api">API Logging</a></li>
            <li><a routerLink="/database-migrations">Database Migrations</a></li>
            <li><a routerLink="/git-deployment">Git Pull Is Deployment</a></li>
            <li><a routerLink="/developer-accounts">Developer Accounts</a></li>
            <li><a routerLink="/get-output">JSON/YAML/XML Output Support</a></li>
            <li><a routerLink="/low-memory-footprint">Low Memory Footprint</a></li>
            <li><a routerLink="/access-management">Access Management Of APIs</a></li>
            <li><a routerLink="/internationalization">Internationalization</a></li>
            <li><a routerLink="/monitoring">Monitoring</a></li>
        </ul>
    </div>
</div>

<!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
